import dayjs from "dayjs"

import { IQuestions } from "components/organisms/Questionnaire"

export const QUESTION_ENTRY = "q1"

export const questions: IQuestions = {
  q1: {
    text: "How can we help?",
    type: "buttons",
    options: [
      {
        id: "purchase",
        text: "I'm looking to buy",
        nextQuestionId: "qPurchase2",
        setQuestionFlowState: {
          buyerType: "purchase",
        },
      },
      {
        id: "remortgage",
        text: "I want help with my existing mortgage",
        nextQuestionId: "qRemortgage2",
        setQuestionFlowState: {
          buyerType: "remortgage",
        },
      },
    ],
  },
  qPurchase2: {
    text: "Are you (or anyone else involved) a first time buyer?",
    type: "buttons",
    nextQuestionId: "qWhichStageAt",
    options: [
      {
        id: "yes",
        text: "Yes",
      },
      {
        id: "no",
        text: "No",
      },
    ],
  },
  qWhichStageAt: {
    text: "What stage are you at?",
    type: "buttons",
    nextQuestionId: "qPurchase3",
    options: [
      {
        id: "12 months+",
        text: "I'll be ready to start looking in 12+ months",
        setQuestionFlowState: {
          priority: undefined,
        },
      },
      {
        id: "ready now",
        text: "I'm viewing/ready to view properties now",
        setQuestionFlowState: {
          priority: "medium",
        },
      },
      {
        id: "made an offer",
        text: "I've made/am ready to make an offer on a property",
        setQuestionFlowState: {
          priority: "high",
        },
      },
    ],
  },
  qPurchase3: {
    text: "How will you use the property?",
    type: "buttons",
    options: [
      {
        id: "live",
        text: "I'll live in it",
        nextQuestionId: "qPurchase5",
      },
      {
        id: "rent",
        text: "I'll rent it out",
        nextQuestionId: "qPurchase4",
      },
    ],
  },
  qRemortgage2: {
    text: "How do you use the property?",
    type: "buttons",
    options: [
      {
        id: "live",
        text: "I live in it",
        nextQuestionId: "qRemortgage4",
      },
      {
        id: "rent",
        text: "I rent it out",
        nextQuestionId: "qRemortgage3",
      },
    ],
  },
  qRemortgage3: {
    text: "How much rent does the property generate?",
    type: "currency",
    validation: { max: 10_000_000 },
    options: [
      {
        id: "monthly-rent",
        text: "Monthly rent",
        nextQuestionId: "qRemortgage4",
      },
    ],
  },
  qPurchase4: {
    text: "How much roughly do you expect to rent it out for?",
    type: "currency",
    validation: { max: 10_000_000 },
    options: [
      {
        id: "monthly-rent",
        text: "Monthly rent",
        nextQuestionId: "qPurchase5",
      },
    ],
  },

  qPurchase5: {
    text: "How much are you looking to spend on a property?",
    type: "currency",
    validation: { max: 10_000_000 },
    nextQuestionId: "qPurchase6",
    options: [
      {
        id: "maximum-purchase-price",
        text: "Maximum purchase price",
      },
    ],
  },
  qRemortgage4: {
    text: "What is the value of your property?",
    type: "currency",
    validation: { max: 10_000_000 },
    nextQuestionId: "qRemortgage5",
    options: [
      {
        id: "estimated-value",
        text: "Estimated value",
      },
    ],
  },
  qPurchase6: {
    text: "How much deposit do you have?",
    type: "currency",
    validation: { max: 10_000_000 },
    nextQuestionId: false,
    options: [
      {
        id: "deposit",
        text: "Deposit amount",
      },
    ],
  },
  qRemortgage5: {
    text: "What is the balance of your current mortgage?",
    type: "currency",
    validation: { max: 10_000_000 },
    nextQuestionId: "qRemortgage6",
    options: [
      {
        id: "current-balance",
        text: "Current balance",
      },
    ],
  },
  qRemortgage6: {
    text: "When does your current rate expire?",
    subtext: "Please enter date in the format DD/MM/YYYY",
    type: "custom",
    nextQuestionId: "qRemortgage7",
    validation: {
      min: dayjs(),
      max: dayjs().add(30, "year"),
    },
    optOutButton: {
      id: "opt-out",
      text: "I don't know / Not applicable",
      setQuestionFlowState: {
        priority: undefined,
      },
    },
    additionalButton: {
      text: "It already has",
      id: "mortgage-rate-expired",
      setQuestionFlowState: {
        priority: "high",
      },
    },
    options: [
      {
        id: "mortgage-rate-expiration",
        text: "Mortgage rate expiration",
      },
    ],
  },
  qRemortgage7: {
    text: "What is the remaining term in years?",
    subtext:
      "For example: if your mortgage started at 25 years it might now have 23 years to go",
    type: "number",
    nextQuestionId: "qRemortgage8",
    validation: { min: 0, max: 40 },
    optOutButton: {
      id: "opt-out",
      text: "I don't know",
    },
    options: [
      {
        id: "mortgage-term",
        text: "Mortgage term",
      },
    ],
  },
  qRemortgage8: {
    text: "Do you want to borrow more than your current mortgage balance?",
    type: "buttons",
    options: [
      {
        id: "yes",
        text: "Yes",
        nextQuestionId: "qRemortgage9",
      },
      {
        id: "no",
        text: "No",
        nextQuestionId: "qRemortgage10",
      },
    ],
  },
  qRemortgage9: {
    text: "How much extra do you want to borrow?",
    type: "currency",
    nextQuestionId: "qRemortgage10",
    options: [
      {
        id: "additional-borrowed-amount",
        text: "Additional borrowed amount",
      },
    ],
  },
  qRemortgage10: {
    text: "How would you like to pay?",
    type: "buttons",
    nextQuestionId: false,
    options: [
      {
        id: "repayment",
        text: "Repayment",
      },
      {
        id: "interest-only",
        text: "Interest only",
      },
      {
        id: "part-and-part",
        text: "Part-part",
      },
      {
        id: "no-preference",
        text: "No preference",
      },
    ],
  },
}
